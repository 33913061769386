<template>
  <div class="row">
    <div class="col-12 col-lg-3 mb-2 mb-lg-0">
      <p class="text-overline">Subscription Plan</p>
    </div>
    <div class="col-12 col-lg-6 mb-4 mb-lg-0">
      <div v-if="planName">
        <strong>{{ planName }}</strong>
        <a-button type="link" size="small" @click="onUnsubscribe">
          Unsubscribe
        </a-button>
        <p class="small">Billing period ends {{ nextChargeAt }}.</p>
      </div>
      <div v-else>
        <strong>Developer</strong>
      </div>
    </div>
    <div class="col-12 col-lg-3 text-lg-end">
      <SubscriptionPlanUpdate
        :visible="visible"
        :plans="plans"
        @cancel="visible = false"
        @success="visible = false"
      >
        <a-button size="small" @click="visible = true">
          Edit
        </a-button>
      </SubscriptionPlanUpdate>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import SubscriptionPlanUpdate from '@/components/Subscription/SubscriptionPlanUpdate'

export default {
  components: {
    SubscriptionPlanUpdate,
  },

  data() {
    return {
      visible: false,
    }
  },

  computed: {
    isSubscribed() {
      return this.$store.state.publication.data.stripe.subscribed
    },
    nextChargeAt() {
      return moment(this.$store.state.publication.data.stripe.nextChargeAt).fromNow()
    },
    plans() {
      return [
        { id: process.env.VUE_APP_STRIPE_PRODUCT_GROWTH, name: 'Growth', articles: 50, price: 25, pricePerArticle: 0.50 },
        { id: process.env.VUE_APP_STRIPE_PRODUCT_SCALE, name: 'Scale', articles: 500, price: 125, pricePerArticle: 0.25 },
      ]
    },
    planName() {
      return this.$store.state.publication.data.stripe.product && this.plans.find(item => item.id === this.$store.state.publication.data.stripe.product).name
    },
  },

  methods: {
    onUnsubscribe() {
      this.$confirm({
        title: 'Confirm Unsubscribe',
        confirmLoading: this.loading,
        content: 'You’re about to unsubscribe and revert back to the free developer plan. It takes effect immediately and unused time will be prorated.',
        okText: 'Unsubscribe',
        okType: 'danger',
        onCancel: () => {},
        onOk: async () => {
          await this.onConfirm()
        },
      })
    },
    async onConfirm() {
      try {
        this.loading = true

        await this.$http.delete(`/publications/${ this.$store.state.publication.data.id }/subscriptions`)

        this.$store.commit('publication/field', {
          stripe: {
            nextCharge: null,
            product: null,
          }
        })

        this.$message.success('Subscription plan has been removed.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
