<template>
  <div class="row">
    <div class="col-12 col-lg-6 mb-4 mb-lg-0">
      <h2 class="h6">Contact</h2>
      <p class="small text-muted">This is shown on the hosted about page and in future invoices. Having a postal address and email address improves your ranking in search results.</p>
    </div>
    <div class="col-12 col-lg-6">
      <div class="d-flex justify-content-between align-items-center">
        <div>{{ address.join(', ') }}</div>
        <div>
          <SettingAddressUpdate
            :visible="visible"
            @cancel="visible = false"
            @success="visible = false"
          >
            <a-button size="small" @click="visible = true">
              Edit
            </a-button>
          </SettingAddressUpdate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingAddressUpdate from '@/components/Setting/SettingAddressUpdate'

export default {
  components: {
    SettingAddressUpdate,
  },

  data() {
    return {
      visible: false,
    }
  },

  computed: {
    address() {
      if (!this.$store.state.publication.data.address) {
        return []
      }

      var address = []
      if (this.$store.state.publication.data.address.name) address.push(this.$store.state.publication.data.address.name)
      if (this.$store.state.publication.data.address.line1) address.push(this.$store.state.publication.data.address.line1)
      if (this.$store.state.publication.data.address.line2) address.push(this.$store.state.publication.data.address.line2)
      if (this.$store.state.publication.data.address.city) address.push(this.$store.state.publication.data.address.city)
      if (this.$store.state.publication.data.address.state) address.push(this.$store.state.publication.data.address.state)
      if (this.$store.state.publication.data.address.postalCode) address.push(this.$store.state.publication.data.address.postalCode)
      if (this.$store.state.publication.data.address.countryCode) address.push(this.$store.state.countries[this.$store.state.publication.data.address.countryCode])
      return address
    },
  },
}
</script>
