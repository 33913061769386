<template>
  <slot />
  <a-modal
    title="Roll Key"
    :confirmLoading="loading"
    :visible="visible"
    okText="Roll Key"
    @cancel="$emit('cancel')"
    @ok="onConfirm"
  >
    Expire key &nbsp;
    <a-select defaultValue="0" style="width: 200px;" @change="onChange">
      <a-select-option value="0">immediately</a-select-option>
      <a-select-option value="1">in 1 hour</a-select-option>
      <a-select-option value="24">in 24 hours</a-select-option>
    </a-select>
    <p class="small text-muted mt-4 mb-0">You can cancel the roll anytime before expiry. After the key expires, you’ll be unable to perform any actions with the old key.</p>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      expiryHours: 0,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/key`, {
          expiry: this.expiryHours,
        })

        this.$message.success('API key has been rolled.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
    onChange(val) {
      this.expiryHours = val
    },
  },
}
</script>
