<template>
  <div class="row align-items-center">
    <div class="col-6">
      <div class="d-flex align-items-center">
        <div class="me-2">
          <img width="32" height="20" :src="getCardIcon(method.card.brand)" :alt="method.card.brand">
        </div>
        <div>
          Ending in <strong>{{ method.card.last4 }}</strong>
          <a-tag v-if="method.id === defaultMethod" class="ms-2" color="blue">Primary</a-tag>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div>Valid until {{ method.card.exp_month}}/{{ method.card.exp_year }}</div>
    </div>
    <div class="col-2 text-end">
      <a-dropdown-button size="small" :trigger="['click']">
        <a-icon type="ellipsis" />
        <template #overlay>
          <a-menu @click="onMenuClick">
            <a-menu-item v-if="method.id !== defaultMethod" key="primary">
              <StarIcon class="icon me-2" />Make Primary
            </a-menu-item>
            <a-menu-divider v-if="method.id !== defaultMethod" />
            <a-menu-item class="text-danger" key="delete">
              <SubscriptionMethodDelete
                :visible="visible"
                :method="method.id"
                :defaultMethod="defaultMethod"
                @cancel="visible = false"
                @success="onSuccess"
              >
                <TrashIcon class="icon me-2" />Delete
              </SubscriptionMethodDelete>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
    </div>
  </div>
</template>

<script>
import { StarIcon, TrashIcon } from '@heroicons/vue/24/outline'
import SubscriptionMethodDelete from '@/components/Subscription/SubscriptionMethodDelete'

export default {
  components: {
    StarIcon,
    SubscriptionMethodDelete,
    TrashIcon,
  },

  props: {
    method: Object,
    defaultMethod: String,
  },

  data() {
    return {
      visible: false,
    }
  },

  methods: {
    async onPrimary() {
      try {
        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/methods/${ this.method.id }`)

        this.$message.success('Payment method has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      }
    },
    onMenuClick(e) {
      switch (e.key) {
        case 'primary':
          this.onPrimary()
          break
        case 'delete':
          this.visible = true
          break
      }
    },
    onSuccess() {
      this.visible = false
      this.$emit('success')
    },
    getCardIcon(brand) {
      var brands = ['alipay', 'amex', 'diners', 'discover', 'elo', 'hipercard', 'jcb', 'maestro', 'mastercard', 'paypal', 'unionpay', 'verve', 'visa']

      if (brands.indexOf(brand) >= 0) {
        return require(`@/assets/images/cards/${ brand }.svg`)
      } else {
        return require(`@/assets/images/cards/default.svg`)
      }
    },
  },
}
</script>
