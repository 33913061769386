<template>
  <slot />
  <a-modal
    title="Change EU VAT ID"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{ htmlType: 'submit' }"
    okText="Save"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <p>Provide the VAT identification number issued to you by your tax administration. VAT in future invoices will be reverse-charged.</p>
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        name="vatId"
      >
        <a-input
          v-model:value="values.vatId"
          name="vatId"
          placeholder="IE6388047V"
        />
      </a-form-item>
    </a-form>
    <p class="small text-muted">Removing your VAT ID may result in VAT surcharges.</p>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        vatId: this.$store.state.publication.data.vat.number,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }`, values)

        this.$store.commit('publication/data', data)
        this.$message.success('VAT ID has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
