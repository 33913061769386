<template>
  <h1 class="h3 mb-4">Invoice History</h1>
  <table class="table mb-0">
    <thead class="thead-light">
      <tr>
        <th>Number</th>
        <th>Created</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="invoice in invoices" class="align-middle">
        <td>
          {{ invoice.number }}
        </td>
        <td>
          {{ invoice.createdAt }}
        </td>
        <td>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <strong>{{ invoice.total.toLocaleString('en-US', { style: 'currency', currency: invoice.currency }) }}</strong>
            </div>
            <div>
              <a-button :href="invoice.link" size="small">
                <ArrowDownTrayIcon class="icon me-2" />Download
              </a-button>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="!invoices.length && loading">
        <td colspan="3" class="text-center text-muted">
          <BaseSpinner :loading="loading">
            <em>No invoices available</em>
          </BaseSpinner>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    ArrowDownTrayIcon,
    BaseSpinner,
  },

  data() {
    return {
      filters: {
        limit: 100,
      },
      invoices: [],
      loading: true,
    }
  },

  async mounted() {
    try {
      this.loading = true

      var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/invoices`, {
        params: this.filters,
      })

      this.invoices = data.invoices
    } catch (err) {
      if (err.response) {
        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }
    } finally {
      this.loading = false
    }
  },
}
</script>
