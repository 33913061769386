<template>
  <a-popover
    title="Confirm Deactivation"
    trigger="click"
    v-model:value="visible"
  >
    <slot />
    <template #content>
      <p>Deactivation will delete your publication in <strong>7 days</strong>. This results in permanent loss of your publication’s content.</p>
      <a-button type="danger" :loading="loading" @click="onConfirm">
        Confirm
      </a-button>
    </template>
  </a-popover>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/deactivate`, {
          active: false,
        })

        this.$message.success('Publication has been deactivated.')

        if (data.deactivatedAt) {
          this.$router.push({ name: 'publications' })
        } else {
          this.$message.error('Publication could not be deactivated.')
        }
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
