<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <div class="py-4 border-bottom">
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
          <h2 class="h6">Publication Details</h2>
          <p class="small text-muted">A brief description is used for SEO-friendly meta tags. Write concises sentences including keywords for which you want to rank.</p>
        </div>
        <div class="col-12 col-lg-6">
          <a-form-item
            label="Description"
            name="description"
          >
            <a-textarea
              v-model:value="values.description"
              :maxlength="250"
              name="description"
              :rows="3"
              showCount
            />
          </a-form-item>
        </div>
      </div>
    </div>
    <div class="py-4 mb-4">
      <SettingAddress />
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <a-button type="primary" html-type="submit" :loading="loading">
        Save
      </a-button>
    </div>
  </a-form>
</template>

<script>
import SettingAddress from '@/components/Setting/SettingAddress'

export default {
  components: {
    SettingAddress,
  },

  data() {
    return {
      loading: false,
      values: {
        description: this.$store.state.publication.data.description,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }`, values)

        this.$store.commit('publication/data', data)
        this.$message.success('Publication settings have been saved.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
