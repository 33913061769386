<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <div class="py-4 border-bottom">
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
          <h2 class="h6">Name &amp; Language</h2>
          <p class="small text-muted">Give your publication a name. It doesn’t have to be unique, but easily identifiable by the contributors of this publication. The default language is selected for new articles.</p>
        </div>
        <div class="col-12 col-lg-6">
          <a-form-item
            label="Name"
            name="name"
            :rules="[
              { required: true, message: 'Name is required.' },
            ]"
          >
            <a-input
              v-model:value="values.name"
              :maxlength="32"
              name="name"
            />
          </a-form-item>
          <a-form-item
            label="Default Language"
            name="language"
            :rules="[
              { required: true, message: 'Language is required.' },
            ]"
          >
            <a-select
              v-model:value="values.language"
              showSearch
              placeholder="Select a language"
            >
              <a-select-option v-for="(name, value) in $store.state.languages" :value="value">
                {{ name }} ({{ value }})
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
    </div>
    <div class="py-4 mb-4">
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
          <h2 class="h6">Hosted Publication</h2>
          <p class="small text-muted">To keep your website canonical, you can block your hosted publication from public access and from search engines. You should uncheck if your website fetches articles from our API.</p>
        </div>
        <div class="col-12 col-lg-6">
          <a-form-item
            name="public"
          >
            <a-checkbox v-model:checked="values.public">Allow Public Access</a-checkbox>
          </a-form-item>
          <a-form-item
            label="Subdomain"
            name="subdomain"
            :rules="[
              { required: true, message: 'Subdomain is required.' },
              { min: 5, message: 'Subdomain must be at least 5 characters.' },
            ]"
          >
            <a-input
              v-model:value="values.subdomain"
              addon-after=".postodian.com"
              :disabled="!values.public"
              :maxlength="30"
              name="subdomain"
            />
          </a-form-item>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <a-button type="primary" html-type="submit" :loading="loading">
        Save
      </a-button>
      <PublicationDeactivate v-if="$store.getters['publication/isAdmin'] && !$store.state.publication.data.deactivatedAt">
        <span class="small text-muted" style="cursor: pointer;">Deactivate Publication</span>
      </PublicationDeactivate>
    </div>
  </a-form>
</template>

<script>
import PublicationDeactivate from '@/components/Publication/PublicationDeactivate'

export default {
  components: {
    PublicationDeactivate,
  },

  data() {
    return {
      loading: false,
      values: {
        name: this.$store.state.publication.data.name,
        subdomain: this.$store.state.publication.data.subdomain,
        public: this.$store.state.publication.data.public,
        language: this.$store.state.publication.data.language,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }`, values)

        this.$store.commit('publication/data', data)
        this.$message.success('Publication settings have been saved.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
