<template>
  <h1 class="h3 mb-4">Subscription</h1>
  <div class="border-bottom py-4">
    <SubscriptionPlan />
  </div>
  <div class="border-bottom py-4">
    <SubscriptionMethod />
  </div>
  <div class="py-4 mb-5">
    <SubscriptionVat />
  </div>
</template>

<script>
import SubscriptionMethod from '@/components/Subscription/SubscriptionMethod'
import SubscriptionPlan from '@/components/Subscription/SubscriptionPlan'
import SubscriptionVat from '@/components/Subscription/SubscriptionVat'

export default {
  components: {
    SubscriptionMethod,
    SubscriptionPlan,
    SubscriptionVat,
  },
}
</script>
