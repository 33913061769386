<template>
  <div class="row">
    <div class="col-12 col-md-8 mb-4 mb-md-0">
      <p class="text-overline">API Key</p>
      <div v-if="apikey.keyRoll">
        <div>
          <code>{{ apikey.keyRoll }}</code>
        </div>
        <div>
          <span class="text-muted small">Old: {{ apikey.key }}</span>
          <a-tag class="ms-2" color="red">Expires {{ getDateFromNow(apikey.keyExpiredAt) }}</a-tag>
        </div>
      </div>
      <div v-else>
        <code>{{ apikey.key }}</code>
      </div>
    </div>
    <div class="col-12 col-md-4 text-md-end">
      <a-dropdown-button size="small" :trigger="['click']">
        <a-icon type="ellipsis" />
        <template #overlay>
          <a-menu @click="onMenuClick">
            <a-menu-item v-if="apikey.keyRoll" key="cancel" class="text-danger">
              <XMarkIcon class="icon me-2" />Cancel Roll
            </a-menu-item>
            <a-menu-item v-else key="roll">
              <KeyRoll
                :visible="visible"
                @cancel="visible = false"
                @success="$emit('success')"
              >
                <ArrowPathIcon class="icon me-2" />Roll Key&hellip;
              </KeyRoll>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
    </div>
  </div>
</template>

<script>
import { ArrowPathIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'
import KeyRoll from '@/components/Key/KeyRoll'

export default {
  components: {
    ArrowPathIcon,
    XMarkIcon,
    KeyRoll,
  },

  props: {
    apikey: Object,
  },

  data() {
    return {
      visible: false,
    }
  },

  methods: {
    getDateFromNow(date) {
      return moment(date).fromNow()
    },
    onMenuClick(e) {
      switch (e.key) {
        case 'cancel':
          this.onCancel()
          break
        case 'roll':
          this.visible = true
          break
      }
    },
    async onCancel() {
      try {
        var { data } = await this.$http.delete(`/publications/${ this.$store.state.publication.data.id }/key`)
        this.$message.success('API key roll has been cancelled.')

        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      }
    },
  },
}
</script>
