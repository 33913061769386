<template>
  <slot />
  <a-modal
    title="Edit Address"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{ htmlType: 'submit' }"
    okText="Save"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Organization Name"
        name="name"
        :rules="[
          { required: true, message: 'Name is required.' },
        ]"
      >
        <a-input
          v-model:value="values.name"
          name="name"
        />
      </a-form-item>
      <a-form-item
        label="Address"
        name="line1"
        :rules="[
          { required: true, message: 'Line 1 is required.' },
        ]"
      >
        <a-input
          v-model:value="values.line1"
          name="line1"
          placeholder="Line 1 (e.g. street, PO box)"
        />
      </a-form-item>
      <a-form-item
        name="line2"
      >
        <a-input
          v-model:value="values.line2"
          name="line2"
          placeholder="Line 2 (e.g. apartment, suite, building)"
        />
      </a-form-item>
      <a-form-item
        name="city"
        :rules="[
          { required: true, message: 'City is required.' },
        ]"
      >
        <a-input
          v-model:value="values.city"
          name="city"
          placeholder="City or Town"
        />
      </a-form-item>
      <div class="row">
        <div class="col-6">
          <a-form-item
            name="state"
          >
            <a-input
              v-model:value="values.state"
              name="state"
              placeholder="State"
            />
          </a-form-item>
        </div>
        <div class="col-6">
          <a-form-item
            name="postalCode"
            :rules="[
              { required: true, message: 'Postal code is required.' },
            ]"
          >
            <a-input
              v-model:value="values.postalCode"
              name="postalCode"
              placeholder="Postal Code"
            />
          </a-form-item>
        </div>
      </div>
      <a-form-item
        name="countryCode"
        :rules="[
          { required: true, message: 'Country is required.' },
        ]"
      >
        <a-select
          v-model:value="values.countryCode"
          name="countryCode"
          placeholder="Country"
          showSearch
        >
          <a-select-option v-for="(name, value) in $store.state.countries" :value="value">
            {{ name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="Email Address"
        name="email"
        :rules="[
          { required: true, message: 'Email address is required.' },
        ]"
      >
        <a-input
          v-model:value="values.email"
          name="email"
          placeholder="name@company.com"
          type="email"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        name: this.$store.state.publication.data.address.name,
        line1: this.$store.state.publication.data.address.line1,
        line2: this.$store.state.publication.data.address.line2,
        city: this.$store.state.publication.data.address.city,
        postalCode: this.$store.state.publication.data.address.postalCode,
        state: this.$store.state.publication.data.address.state,
        countryCode: this.$store.state.publication.data.address.countryCode,
        email: this.$store.state.publication.data.address.email,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }`, {
          address: values,
        })

        this.$store.commit('publication/address', data.address)
        this.$message.success('Billing address has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
