<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="h3 mb-0">API Access Key</h1>
    <div>
      <a href="https://postodian.com/docs" target="_blank">API Documentation <ArrowTopRightOnSquareIcon class="icon ms-1" /></a>
    </div>
  </div>
  <BaseSpinner :loading="loading">
    <KeyItem
      v-for="(apikey, i) in apikeys"
      :apikey="apikey"
      class="py-4"
      :class="{ 'border-bottom': i < apikeys.length - 1 }"
      @success="listKeys"
    />
  </BaseSpinner>
</template>

<script>
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import BaseSpinner from '@/components/Base/BaseSpinner'
import KeyItem from '@/components/Key/KeyItem'

export default {
  components: {
    ArrowTopRightOnSquareIcon,
    BaseSpinner,
    KeyItem,
  },

  data() {
    return {
      loading: false,
      apikeys: []
    }
  },

  mounted() {
    this.listKeys()
  },

  methods: {
    async listKeys() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/key`)

        this.apikeys = [data]
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
