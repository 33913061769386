<template>
  <slot />
  <a-modal
    title="Change Subscription"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :visible="visible"
    :okButtonProps="{ disabled: disabled }"
    okText="Confirm"
    @cancel="$emit('cancel')"
    @ok="onConfirm"
  >
      <div
        v-for="plan in plans"
        class="d-flex justify-content-between"
        :class="{
          'p-3': true,
          'mb-2': true,
          'border': true,
          'border-primary': plan.id === values.product,
          'border-primary-inset': plan.id === values.product,
          'rounded': true,
        }"
      >
        <div>
          <a-radio v-model:value="values.product" :disabled="plan.id === $store.state.publication.data.stripe.product">
            <strong>{{ plan.name }}</strong>
            <a-tag v-if="plan.id === $store.state.publication.data.stripe.product" class="ms-2" color="blue">Subscribed</a-tag>
          </a-radio>
        </div>
        <div>
          <p><strong>${{ plan.price }}</strong><span class="small text-muted">/mo</span></p>
          <div>
            {{ plan.articles }} articles included
          </div>
          <p class="small text-muted">${{ plan.pricePerArticle.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} per additional article</p>
        </div>
      </div>
    <p class="small text-muted">All prices are in USD, <strong>exclusive</strong> of valued added tax. Overages may apply.</p>
  </a-modal>
</template>

<script>
export default {
  props: {
    plans: Array,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        product: this.$store.state.publication.data.stripe.product,
      },
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/subscriptions`, this.values)

        this.$store.commit('publication/data', data)
        this.$message.success('Subscription plan has been changed.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },

  computed: {
    disabled() {
      return this.values.product === this.$store.state.publication.data.stripe.product
    },
  },
}
</script>
