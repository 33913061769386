<template>
  <slot />
  <a-modal
    title="New Payment Method"
    :visible="visible"
    :confirmLoading="loading"
    @ok="onConfirm"
    okText="Save"
    @cancel="$emit('cancel')"
  >
    <div class="ant-input mb-4" id="card-element" style="padding-top: 11px;"></div>
    <p class="small text-muted">Payments are processed securely through <a href="https://stripe.com" target="_blank">Stripe</a>. You authorise Postodian to send instructions to the financial institution that issued your card to take payments from your card account in accordance with the terms of your agreement with us.</p>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      activeTab: 'card',
      name: null,
      email: null,
      stripeCard: null,
    }
  },

  computed: {
    stripe() {
      return Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
    },
  },

  created() {
    var elements = this.stripe.elements({
      fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Public+Sans:400' }]
    })
    this.stripeCard = elements.create('card', {
      style: {
        base: {
          color: '#2b2d3a',
          fontFamily: '"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontSmoothing: 'antialiased',
          fontSize: '15px',
        },
      },
    })
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        var { data } = await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/methods/intent`)
        var { setupIntent } = await this.stripe.handleCardSetup(data.client_secret, this.stripeCard)

        await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/methods`, {
          paymentMethod: setupIntent.payment_method,
        })

        this.$message.success('Payment method has been saved.')
        this.$emit('success')
      } catch (err) {
        this.loading = false

        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    visible(value) {
      if (value) {
        setTimeout(() => {
          this.stripeCard.mount('#card-element')
        }, 250)
      }
    },
  },
}
</script>
