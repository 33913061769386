<template>
  <div class="row">
    <div class="col-12 col-lg-3 mb-2 mb-lg-0">
      <p class="text-overline">EU VAT ID</p>
    </div>
    <div class="col-12 col-lg-6 mb-4 mb-lg-0">
      <span v-if="$store.state.publication.data.vat.number">
        {{ $store.state.publication.data.vat.number }}
      </span>
      <span v-else class="text-muted">
        <em>None</em>
      </span>
    </div>
    <div class="col-12 col-lg-3 text-lg-end">
      <SubscriptionVatUpdate
        :visible="visible"
        @cancel="visible = false"
        @success="onSuccess"
      >
        <a-button size="small" @click="visible = true">
          Edit
        </a-button>
      </SubscriptionVatUpdate>
    </div>
  </div>
</template>

<script>
import SubscriptionVatUpdate from '@/components/Subscription/SubscriptionVatUpdate'

export default {
  components: {
    SubscriptionVatUpdate,
  },

  data() {
    return {
      visible: false,
    }
  },

  methods: {
    onSuccess() {
      this.visible = false
    },
  },
}
</script>
