<template>
  <div class="row">
    <div class="col-12 col-lg-3 mb-2 mb-lg-0">
      <p class="text-overline">Payment Method</p>
    </div>
    <div class="col-12 col-lg-6 mb-4 mb-lg-0">
      <BaseSpinner :loading="loading">
        <div
          v-for="(method, index) in methods"
          :class="{
            'mb-4': index < methods.length - 1,
          }
        ">
          <SubscriptionMethodItem
            :method="method"
            :defaultMethod="defaultMethod"
            @success="listCards"
          />
        </div>
        <div v-if="!methods.length" class="text-muted">
          <em>None</em>
        </div>
      </BaseSpinner>
    </div>
    <div class="col-12 col-lg-3 text-lg-end">
      <SubscriptionMethodCreate
        :visible="visible"
        @cancel="visible = false"
        @success="listCards"
      >
        <a-button size="small" :disabled="loading" @click="visible = true">
          <PlusIcon class="icon me-2" />Add
        </a-button>
      </SubscriptionMethodCreate>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import BaseSpinner from '@/components/Base/BaseSpinner'
import SubscriptionMethodCreate from '@/components/Subscription/SubscriptionMethodCreate'
import SubscriptionMethodItem from '@/components/Subscription/SubscriptionMethodItem'

export default {
  components: {
    BaseSpinner,
    PlusIcon,
    SubscriptionMethodCreate,
    SubscriptionMethodItem,
  },

  data() {
    return {
      loading: false,
      visible: false,
      methods: [],
      methodsCount: null,
      defaultMethod: null,
    }
  },

  async mounted() {
    await this.listCards()
  },

  methods: {
    async listCards() {
      try {
        this.loading = true
        this.visible = false

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/methods`)

        this.methods = data.methods
        this.methodsCount = data.methodsCount
        this.defaultMethod = data.defaultMethod
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
